import { Box, Typography, CircularProgress } from "@mui/material";

export default function CircularLoading({ boxSx, loaderSx, loading, size }) {
  return (
    <>
      {boxSx && (
        <Box sx={{ ...boxSx }}>
          <CircularProgress sx={{ ...loaderSx }} size={size} />
          {loading && (
            <Typography variant="body2" sx={{ mt: 2, pl: 1 }}>
              Loading...
            </Typography>
          )}
        </Box>
      )}
      {loaderSx && (
        <>
          <CircularProgress sx={{ ...loaderSx }} size={size} />
          {loading && (
            <Typography variant="body2" sx={{ mt: 2, pl: 1 }}>
              Loading...
            </Typography>
          )}
        </>
      )}
    </>
  );
}

import React, { createContext, useContext, useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { FIND_BRANDING } from './graphQL/queries';
import ExtractSubdomain from './utils/helpers/ExtractSubdomain';

const BrandingContext = createContext();

export const BrandingProvider = ({ children }) => {
  const [branding, setBranding] = useState(null);
  const { loading, error, data } = useQuery(FIND_BRANDING, { variables: { subdomain: ExtractSubdomain(window.location.hostname) } });

  useEffect(() => {
    if (loading) {
      console.log("Loading...");
    } else if (error) {
      console.error("Error:", error.message);
    } else if (data) {
      setBranding(data.findBranding);
    }
  }, [loading, error, data]);

  return (
    <BrandingContext.Provider value={{ loading, error, branding }}>
      {children}
    </BrandingContext.Provider>
  );
};

export const useBrandingContext = () => useContext(BrandingContext);
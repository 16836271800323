const sharedFeeTransactionFields = `
    _id
    createdAt
    dateReceived
    dateRequested
    feeType
    product {
        ... on DafProduct {
        _id
        name
        }
    }
    quarter
    receivedAmount
    requestedAmount
    status
    waivedAmount
    year
    users {
        _id
        associationType
        user {
            ... on InternalUser {
                _id
                firstName
                lastName
            }
            ... on DonorUser {
                _id
                firstName
                lastName
            }
            ... on FirmUser {
                _id
                firstName
                lastName
            }
            ... on EntityUser {
                _id
                firstName
                lastName
            }
        }
    }
`;

export { sharedFeeTransactionFields };
import { gql } from "@apollo/client";




const GENERATE_LINK_TOKEN = gql`
query GenerateLinkToken($config: GenerateLinkTokenConfigs) {
  generateLinkToken(config: $config) {
    expiration
    link_token
    request_id
  }
}
`;

const FIND_PLAID_ACCESS_TOKENS = gql`
query FindPlaidAccessToken($args: UpdatePlaidAccessTokenInput) {
  findPlaidAccessToken(args: $args) {
    _id
    accessToken
    brokerage
    brokerageLogin
    itemId
    status
    tokenType
    numFailedAssets
    createdAt
    updatedAt
  }
}
`;



export { GENERATE_LINK_TOKEN, FIND_PLAID_ACCESS_TOKENS };
import { gql } from "@apollo/client";

const QUERY_USER_INVITE_BY_TOKEN = gql`
  query FindUserInviteByToken($inviteConfirmationToken: String!, $inviteType: String!) {
    findUserInviteByToken(
      inviteConfirmationToken: $inviteConfirmationToken
      inviteType: $inviteType
    ) {
      _id
      inviteStatus
    }
  }
`;

const FIND_USER_INVITES = gql`
  query Query {
    findUserInvites {
      _id
      inviteType
      meta {
        notified
      }
      linkedDocumentId {
        _id
        entityName
      }
    }
  }
`;

export { QUERY_USER_INVITE_BY_TOKEN, FIND_USER_INVITES };

import { gql } from "@apollo/client";

const FIND_NOTIFICATIONS = gql`
  query FindNotifications($args: UpdateNotificationInput) {
    findNotifications(args: $args) {
      _id
      createdAt
      createdBy
      endDate
      notificationType
      title
      message
      productId
      severity
      startDate
      targetRoles
      updatedAt
      updatedBy
      userId
      meta {
        isDeleted
        testData
      }
    }
  }
`;

const FIND_ACTIVE_USER_NOTIFICATIONS = gql`
  query FindActiveUserNotifications($args: UpdateNotificationInput) {
    findActiveUserNotifications(args: $args) {
      _id
      createdAt
      createdBy
      endDate
      hasRead
      message
      meta {
        isDeleted
        testData
      }
      productId
      severity
      startDate
      targetRoles
      notificationType
      title
      updatedAt
      updatedBy
      userId
    }
  }
`;

export { FIND_NOTIFICATIONS, FIND_ACTIVE_USER_NOTIFICATIONS };

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoggedIn: false,
}


const generalSlice = createSlice({
  name: 'general',
  initialState: initialState,
  reducers: {
    UPDATE_ISLOGGEDIN: (state, action) => {
      state.isLoggedIn = action.payload;
    },
  }
})

export const {
  UPDATE_ISLOGGEDIN,
} = generalSlice.actions

export default generalSlice.reducer
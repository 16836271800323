export default function ExtractSubdomain(hostname) {
  if (hostname.includes("localhost")) {
    hostname += ".TLD" // placeholder top-level domain ending
  }
  const parts = hostname.split(".");

  // A list of our subdomains/parts to ignore
  const commonSubdomains = ["www"];

  // Filter out the common subdomains
  const specificSubdomains = parts.filter((part) => !commonSubdomains.includes(part));

  // Assuming the second last part is always the domain (like 'uicharitable')
  // and the last part is the top-level domain (like '.org')
  // Therefore, the specific subdomain is the part just before the domain
  if (specificSubdomains.length > 2) {
    return specificSubdomains.slice(0, -2).join(".");
  } else {
    return "";
  }
}

import { gql } from "@apollo/client";

const QUERY_USER_PRODUCT_BY_TOKEN = gql`
  query FindUserProductByToken(
    $associationType: AssociationType!
    $inviteConfirmationToken: String!
  ) {
    findUserProductByToken(
      associationType: $associationType
      inviteConfirmationToken: $inviteConfirmationToken
    ) {
      _id
      inviteStatus
    }
  }
`;

const FIND_USER_PRODUCTS_BY_USER = gql`
  query FindUserProductByUserId {
    findUserProductByUserId {
      _id
      associationType
      product {
        ... on DafProduct {
          _id
          name
        }
      }
    }
  }
`;

export { QUERY_USER_PRODUCT_BY_TOKEN, FIND_USER_PRODUCTS_BY_USER };

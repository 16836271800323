import { gql } from "@apollo/client";

const QUERY_CHARITY = gql`
  query CharitySearch($search: String) {
    charitySearch(search: $search) {
      _id
      friendlyId
      address {
        city
        country
        state
      }
      entityName
      entityType
      charityType
      ein
      highlights
      score
    }
  }
`;

const QUERY_CHARITY_BY_EIN = gql`
  query charitySearchByEin($search: String) {
    charitySearchByEin(search: $search) {
      _id
      friendlyId
      address {
        city
        country
        state
      }
      entityName
      entityType
      charityType
      ein
    }
  }
`;

export { QUERY_CHARITY, QUERY_CHARITY_BY_EIN };
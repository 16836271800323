import { gql } from "@apollo/client";

const { sharedUserFields, advisorUserFields } = require("../fragments/user");

const sharedProductUserFields = `
_id
firstName
lastName
email
`;

const sharedSuccessorObject = `
successors {
  address1
  address2
  city
  country
  createdAt
  ein
  email
  firstName
  lastName
  organizationName
  organizationNotes
  percentOfDaf
  phone
  relationship
  state
  status
  type
  updatedAt
  zip
}
`;

const sharedProductFields = `
_id
createdAt
friendlyId
name
productType
updatedAt
fundingAccount {
  ... on AccountAsset {
    _id
    accountLogin
    accountNumber
    assetName
    institution
  }
}
productAgreements {
  dateSigned
  signedBy
}
users {
  _id
  associationType
  updatedAt
  createdAt
  expectedEmail
  expectedFirstName
  expectedLastName
  inviteConfirmationToken
  inviteExpirationDate
  inviteStatus
  relationship
  successorPriority
  meta {
    testData
  }
  user {
    ... on InternalUser {
      ${sharedProductUserFields}
    }
    ... on DonorUser {
      ${sharedProductUserFields}
    }
    ... on FirmUser {
      ${sharedProductUserFields}
      crd
      firmCrd
      firmName
    }
    ... on EntityUser {
      ${sharedProductUserFields}
    }
  }
}
`;

const productObjectFields = `
... on DafProduct {
  ${sharedProductFields}
  totals {
    cash
    stock
    alternativeAssets
    crypto
    other
    availableToGrant
    pendingGrantPayments
    ytdGranted
    totalGranted
    ytdContributed
    totalContributed
    balance
  }
  ${sharedSuccessorObject}
}
... on ClatProduct {
  ${sharedProductFields}
  ${sharedSuccessorObject}
}
... on BrandingProduct {
  ${sharedProductFields}
}
`;

const userProductObjectFields = `
_id
associationType
createdAt
product {
  ${productObjectFields}
}
`;

const sharedEntityFields = `
_id
friendlyId
address {
  address1
  address2
  city
  country
  state
  zip
}
entityName
phone
primaryEmail
entityType
`;

const QUERY_USER = gql`
  query User {
    user {
      ... on InternalUser {
        ${sharedUserFields}
        products {
          ${userProductObjectFields}
        }
      }
      ... on DonorUser {
        advisor {
        _id
        firstName
        lastName
        entities {
          _id
          entity {
            ... on FirmEntity {
              _id
              entityName
            }
            ... on BranchEntity {
              _id
              entityName
            }
          }
        }
      }
        ${sharedUserFields}
        products {
          ${userProductObjectFields}
        }
      }
      ... on FirmUser {
        ${sharedUserFields}
        ${advisorUserFields}
        products {
          ${userProductObjectFields}
        }
        advisedProducts {
          ${userProductObjectFields}
        }
        advisedUsers { # these are donor users
          ${sharedUserFields}
          products {
            ${userProductObjectFields}
          }
        }
        entities {
        entity {
          ... on PartnerEntity {
            _id
            ${sharedEntityFields}
          }
          ... on FirmEntity {
            crd
            ${sharedEntityFields}
            users {
              user {
                ... on FirmUser {
                  _id
                  firstName
                  lastName
                  totals {
                    date
                    aum
                    portfolioBalance
                    crypto
                    cash
                    stock
                    alternativeAssets
                    other
                  }
                  advisedUsers {
                    _id
                    firstName
                    lastName
                    totals {
                      date
                      portfolioBalance
                      crypto
                      cash
                      stock
                      alternativeAssets
                      other
                    }
                  }
                }
              }
            }
          }
          ... on BranchEntity {
            ${sharedEntityFields}
            firm {
              _id
              crd
            }
            users {
              user {
                ... on FirmUser {
                  _id
                  firstName
                  lastName
                  totals {
                    date
                    aum
                    portfolioBalance
                    crypto
                    cash
                    stock
                    alternativeAssets
                    other
                  }
                  advisedUsers {
                    _id
                    firstName
                    lastName
                    totals {
                      date
                      portfolioBalance
                      crypto
                      cash
                      stock
                      alternativeAssets
                      other
                    }
                  }
                }
              }
            }
          }
        }
      }
      }
      ... on EntityUser {
        ${sharedUserFields}
        products {
          ${userProductObjectFields}
        }
      }
      ... on UnknownUser {
        ${sharedUserFields}
      }
    }
  }
`;

const QUERY_USER_BY_ID = gql`
  query FindUserById($userId: ID!) {
    findUserById(userId: $userId) {
      ... on InternalUser {
        ${sharedUserFields}
        products {
          ${userProductObjectFields}
        }
      }
      ... on DonorUser {
        advisor {
        _id
        firstName
        lastName
        entities {
          _id
          entity {
            ... on FirmEntity {
              _id
              entityName
            }
            ... on BranchEntity {
              _id
              entityName
            }
          }
        }
      }
        ${sharedUserFields}
        products {
          ${userProductObjectFields}
        }
      }
      ... on FirmUser {
        ${sharedUserFields}
        ${advisorUserFields}
        products {
          ${userProductObjectFields}
        }
        advisedProducts {
          ${userProductObjectFields}
        }
        advisedUsers { # these are donor users
          ${sharedUserFields} 
          products {
            ${userProductObjectFields}
          }
        }
        entities {
          _id
          entity {
            ... on PartnerEntity {
              _id
              ${sharedEntityFields}
            }
            ... on FirmEntity {
              crd
              ${sharedEntityFields}
              users {
                associationType
                user {
                  ... on FirmUser {
                    _id
                    firstName
                    lastName
                    totals {
                      date
                      aum
                      portfolioBalance
                      crypto
                      cash
                      stock
                      alternativeAssets
                      other
                    }
                    advisedUsers {
                      _id
                      firstName
                      lastName
                      totals {
                        date
                        portfolioBalance
                        crypto
                        cash
                        stock
                        alternativeAssets
                        other
                      }
                    }
                  }
                }
              }
            }
            ... on BranchEntity {
              ${sharedEntityFields}
              firm {
                _id
                crd
              }
              users {
                associationType
                user {
                  ... on FirmUser {
                    _id
                    firstName
                    lastName
                    totals {
                      date
                      aum
                      portfolioBalance
                      crypto
                      cash
                      stock
                      alternativeAssets
                      other
                    }
                    advisedUsers {
                      _id
                      firstName
                      lastName
                      totals {
                        date
                        portfolioBalance
                        crypto
                        cash
                        stock
                        alternativeAssets
                        other
                      }
                    }
                  }
                }
              }
            }
          }
      }
      }
      ... on EntityUser {
        ${sharedUserFields}
        products {
          ${userProductObjectFields}
        }
      }
      ... on UnknownUser {
        ${sharedUserFields}
      }
    }
  }
`;

const FIND_ALL_USERS_WITHOUT_ADVISORS = gql`
  query FindAllUsersWithoutAdvisors($page: Int, $pageSize: Int) {
    findAllUsersWithoutAdvisors(page: $page, pageSize: $pageSize) {
      pageInfo {
        currentPage
        pageSize
        totalDocuments
        totalPages
      }
      users {
        ... on DonorUser {
          _id
          firstName
          lastName
        }
      }
    }
  }
`;

const FIND_ALL_USERS = gql`
  query FindAllUsers($page: Int, $pageSize: Int) {
    findAllUsers(page: $page, pageSize: $pageSize) {
      pageInfo {
        currentPage
        pageSize
        totalDocuments
        totalPages
      }
      users {
        ... on InternalUser {
          _id
          firstName
          lastName
          email
          type
          roles
          meta {
            testData
          }
        }
        ... on DonorUser {
          _id
          firstName
          lastName
          email
          type
          roles
          meta {
            testData
          }
          advisor {
            _id
            firstName
            lastName
            entities {
              entity {
                ... on FirmEntity {
                  _id
                  entityName
                  partner {
                    ... on PartnerEntity {
                      _id
                      entityName
                    }
                  }
                }
                ... on BranchEntity {
                  _id
                  entityName
                  partner {
                    ... on PartnerEntity {
                      _id
                      entityName
                    }
                  }
                }
              }
            }
          }
        }
        ... on FirmUser {
          _id
          firstName
          lastName
          email
          type
          roles
          meta {
            testData
          }
          entities {
            _id
            entity {
              ... on PartnerEntity {
                _id
                entityName
              }
              ... on FirmEntity {
                _id
                entityName
                partner {
                  ... on PartnerEntity {
                    _id
                    entityName
                  }
                }
              }
              ... on BranchEntity {
                _id
                entityName
                partner {
                  ... on PartnerEntity {
                    _id
                    entityName
                  }
                }
              }
            }
          }
        }
        ... on EntityUser {
          _id
          firstName
          lastName
          email
          type
          meta {
            testData
          }
        }
      }
    }
  }
`;

const FIND_ONBOARDING_USERS = gql`
  query FindOnboardingUsers {
    findOnboardingUsers {
      ... on DonorUser {
        _id
        firstName
        lastName
        createdAt
        email
        type
        roles
        meta {
          onboarded
          source
        }
        advisor {
          _id
          firstName
          lastName
          entities {
            _id
            entity {
              ... on FirmEntity {
                _id
                entityName
                users {
                  _id
                  user {
                    ... on FirmUser {
                      _id
                      firstName
                      lastName
                      roles
                    }
                  }
                }
              }
              ... on BranchEntity {
                _id
                entityName
                users {
                  _id
                  user {
                    ... on FirmUser {
                      _id
                      firstName
                      lastName
                      roles
                    }
                  }
                }
              }
            }
          }
        }
        products {
          _id
          associationType
          product {
            ... on DafProduct {
              _id
              fundingAccount {
                ... on AccountAsset {
                  _id
                }
              }
            }
          }
        }
      }
      ... on FirmUser {
        _id
        firstName
        lastName
        firmCrd
        email
        firmName
        createdAt
        type
        roles
        meta {
          onboarded
          source
        }
        entities {
          _id
          entity {
            ... on FirmEntity {
              _id
              entityName
            }
            ... on BranchEntity {
              _id
              entityName
            }
          }
        }
      }
    }
  }
`;

const USER_TOTALS = gql`
  query FindTotalUsers {
    findTotalUsers {
      advisors
      donors
    }
  }
`;

const FIND_FIRM_USER_METRICS = gql`
  query FindFirmUserMetrics($entityId: ID!, $entityType: EntityTypeEnum!) {
    findFirmUserMetrics(entityId: $entityId, entityType: $entityType) {
      aum
      totalClients
    }
  }
`;

const FIND_PARTNER_USERS = gql`
  query FindPartnerUsers($partnerId: ID!) {
    findPartnerUsers(partnerId: $partnerId) {
      _id
      firstName
      lastName
      email
      roles
    }
  }
`;

export {
  QUERY_USER,
  QUERY_USER_BY_ID,
  FIND_ALL_USERS,
  FIND_ONBOARDING_USERS,
  USER_TOTALS,
  FIND_ALL_USERS_WITHOUT_ADVISORS,
  FIND_FIRM_USER_METRICS,
  FIND_PARTNER_USERS,
};

import decode from "jwt-decode";

class AuthService {
  getData() {
    try {
      let token = this.getToken();
      return token ? decode(token): null;
    }
    catch (err) {
      console.log("auth.getData error", err)
      return null;
    }
  }

  loggedIn() {
    // Checks if there is a saved token and it's still valid
    try {
      const token = this.getToken();

      if (token && !this.isTokenExpired(token)) {
        return true;
      }
      
      return false;
    }
    catch (err) {
      console.log("auth.loggedIn error", err)
      return false;
    }
  }

  getTokensExpiration() {
    try {
      const decodedAccessToken = decode(this.getToken());
      const decodedRefreshToken = decode(localStorage.getItem("refreshToken"));
  
      return {
        accessTokenExp: decodedAccessToken.exp,
        refreshTokenExp: decodedRefreshToken.exp,
        accessTokenIat: decodedAccessToken.iat
      };
    } catch (err) {
      console.log("getTokensExpiration error", err);
      return false;
    }
  }
  

  checkEmailVerified() {
    try {
      const token = this.getToken();
      const decodedToken = decode(token);

      if (decodedToken.data.emailVerified || decodedToken.data.devSkip) {
        // window.location.assign("/");
        return true;
      }

      return false;
    }
    catch (err) {
      console.log("auth.checkEmailVerified error", err)
      return false;
    }
  }

  isTokenExpired(token) {
    try {
      const decoded = decode(token);
      if (decoded.exp < Date.now() / 1000) {
        return true;
      } else return false;
    } catch (err) {
      console.log("auth.isTokenExpired error", err);
      return false;
    }
  }

  setToken(idToken, refreshToken) {
    // saves user token to localStorage
    localStorage.setItem("idToken", idToken);
    localStorage.setItem("refreshToken", refreshToken);

  }

  getToken() {
    // Retrieves the user token from localStorage
    try {
      const token = localStorage.getItem("idToken");
      return token ? token : null;
    } catch (err) {
      console.log("auth.getToken error", err);
      // delete token if it's "[object Object]"
      localStorage.removeItem("idToken");
      return null;
    }
  }

  getDecryptedToken() {
    const token = this.getToken();
    return token ? decode(token) : null;
  }

  login(idToken, refreshToken) {
    // Saves user token to localStorage
    localStorage.setItem("idToken", idToken);
    // this enables navigation after typing in credentials
    // window.location.assign("/");
    localStorage.setItem("refreshToken", refreshToken);

  }

  logout() {
    // reset to defaults
    sessionStorage.setItem("contextUserId", "")
    sessionStorage.setItem("contextProduct", 0)
    // Clear user token and profile data from localStorage
    localStorage.removeItem("idToken");
    localStorage.removeItem("refreshToken");
    // Clear out transaction/asset route defaults
    sessionStorage.removeItem("transactionType");
    sessionStorage.removeItem("assetType");
    sessionStorage.removeItem("feeType");
    sessionStorage.removeItem("entityType");
    // this will reload the page and reset the state of the application
    // window.location.assign("/");
  }
}

export default new AuthService();

import { gql } from "@apollo/client";

const FIND_PRODUCT_DOCUMENTS = gql`
  query DocumentsByProduct($productId: ID!) {
    documentsByProduct(productId: $productId) {
      _id
      createdAt
      fileName
      filePath
      fileCategory
      fileType
      s3Key
      updatedAt
    }
  }
`;

const FIND_ASSET_DOCUMENTS = gql`
  query DocumentsByAsset($assetId: ID!) {
    documentsByAsset(assetId: $assetId) {
      giftAgreementFee
      documents {
        _id
        createdAt
        fileName
        filePath
        fileCategory
        status
        executionDate
        signedBy
        fileType
        s3Key
        updatedAt
      }
    }
  }
`;

const FIND_TRANSACTION_DOCUMENTS = gql`
  query DocumentsByTransaction($transactionId: ID!) {
    documentsByTransaction(transactionId: $transactionId) {
      _id
      createdAt
      fileName
      filePath
      fileCategory
      fileType
      s3Key
      updatedAt
    }
  }
`;

const FIND_ENTITY_DOCUMENTS = gql`
  query DocumentsByEntity($entityId: ID!) {
    documentsByEntity(entityId: $entityId) {
      _id
      createdAt
      fileCategory
      fileName
      filePath
      fileType
      product {
        ... on DafProduct {
          _id
          name
          assets {
            ... on EquityAsset {
              _id
              assetName
            }
          }
        }
      }
      s3Key
      updatedAt
    }
  }
`;

const FIND_PRODUCT_SPECIFIC_DOCUMENTS = gql`
  query ProductSpecificDocuments($productId: ID!) {
    productSpecificDocuments(productId: $productId) {
      _id
      createdAt
      executionDate
      fileCategory
      fileName
      s3Key
    }
  }
`;

const FIND_UNCATEGORIZED_DOCUMENTS = gql`
  query UncategorizedDocuments {
    uncategorizedDocuments {
      _id
      asset {
        ... on EquityAsset {
          _id
          assetName
        }
      }
      createdAt
      executionDate
      fileCategory
      fileName
      filePath
      fileType
      product {
        ... on DafProduct {
          _id
          name
          assets {
            ... on EquityAsset {
              _id
              assetName
            }
          }
        }
      }
      requestedByUserId {
        ... on InternalUser {
          _id
          firstName
          lastName
        }
        ... on DonorUser {
          _id
          firstName
          lastName
        }
        ... on FirmUser {
          _id
          firstName
          lastName
        }
        ... on EntityUser {
          _id
          firstName
          lastName
        }
      }
      s3Key
      user {
        ... on InternalUser {
          _id
          firstName
          lastName
          type
        }
        ... on DonorUser {
          _id
          firstName
          lastName
          type
        }
        ... on FirmUser {
          _id
          firstName
          lastName
          type
        }
        ... on EntityUser {
          _id
          firstName
          lastName
          type
        }
      }
    }
  }
`;

const FIND_REQUESTED_AGREEMENTS = gql`
  query FindRequestedAgreements($productId: ID!) {
    findRequestedAgreements(productId: $productId) {
      _id
      executionDate
      fileCategory
      signedBy
      status
      asset {
        ... on EquityAsset {
          _id
          assetName
          assetType
          feeTransactions {
            _id
            feeType
            status
            requestedAmount
          }
        }
        ... on LendingAsset {
          _id
          assetName
          assetType
          feeTransactions {
            _id
            feeType
            status
            requestedAmount
          }
        }
      }
    }
  }
`;

export {
  FIND_PRODUCT_DOCUMENTS,
  FIND_ENTITY_DOCUMENTS,
  FIND_TRANSACTION_DOCUMENTS,
  FIND_PRODUCT_SPECIFIC_DOCUMENTS,
  FIND_ASSET_DOCUMENTS,
  FIND_UNCATEGORIZED_DOCUMENTS,
  FIND_REQUESTED_AGREEMENTS,
};
